import React from "react";
import meny2 from "./dashi-drink-meny.jpeg";
import meny from "./Dashi-meny.jpeg";

const Meny = () => {
  return (
    <div className="meny-container">
      <img id="food" src={meny} alt="meny" />
      <img id="drinks" src={meny2} alt="meny2" />
    </div>
  );
};

export default Meny;
