import { useState } from "react";
import React from "react";
import logo from "./dashi-logo-2-removebg-preview.png";

const Header = () => {
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 40) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <div className="header-container">
      <nav className={colorChange ? "navbar colorChange" : "navbar"}>
        <ul className="nav-ul">
          <a href="#food">
            <li className="Link">Food</li>
          </a>
          <a href="#drinks">
            <li className="Link">Drinks</li>
          </a>
          <a href="#om">
            <img className="header-logo" src={logo} alt="dashi-logo" />
          </a>
          <a href="#order">
            <li className="Link">Order</li>
          </a>
          <a href="#om">
            <li className="Link">About</li>
          </a>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
