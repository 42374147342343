import React from "react";

const Order = () => {
  return (
    <div id="order" className="order-container">
      <h3>Time for dinner, lunch or a snack?</h3>
      <div ontouchstart="">
        <a href="https://weiq.app/dashi">
          <img
            className="ramen"
            alt="order-link"
            src="https://cdn.icon-icons.com/icons2/1556/PNG/512/3377055-bowl-food-noodle-ramen_107434.png"
          ></img>
        </a>
        <h3>Press the ramen to order now!</h3>
      </div>
    </div>
  );
};

export default Order;
