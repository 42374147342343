import React from "react";
import facebook from "./icons8-facebook-96.png";
import instagram from "./icons8-instagram-96.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <p>+46 40 781 85, Holmgatan 4, Malmö, Sweden</p>
      <div>
        <a href="https://www.facebook.com/dashimalmo/">
          <img className="logo" src={facebook} alt="facebook" />
        </a>
        <a href="https://www.instagram.com/Dashi_malmo/">
          <img className="logo" src={instagram} alt="instagram" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
