import "./App.css";
import Footer from "./Footer";
import Header from "./Header";
import Meny from "./Meny";
import Om from "./Om";
import Order from "./Order";

function App() {
  return (
    <div className="App">
      <Header />
      <Om />
      <Meny />
      <Order />
      <Footer />
    </div>
  );
}

export default App;
