import React from "react";

const Om = () => {
  return (
    <div id="om" className="om-container">
      <div className="location">
        <h1>Japanese and vietnamese harmony</h1>
        <p>
          Embark on a delightful culinary journey that delves deep into the
          heart of Japanese and Vietnamese street food culture, where we take
          immense pride in our commitment to creating the finest ramen and pho
          dishes from the ground up. These traditional delicacies are
          meticulously prepared with painstaking attention to detail and an
          extra measure of love, ensuring that every bowl is a true masterpiece.
          Our dedication to authenticity shines through in every aspect of our
          food, from sourcing the finest ingredients to hand-crafting our broths
          and noodles. We draw inspiration from the bustling streets of Japan
          and Vietnam, capturing the essence of their vibrant food scenes, while
          adding our unique touch to create a remarkable dining experience. Join
          us and savor the results of our unwavering passion and expertise, as
          we serve up bowls of ramen and pho that are not just meals, but
          journeys into the heart and soul of these beloved Asian street food
          traditions.
        </p>
      </div>
      <div className="information">
        <h1>Contact / Kontakt:</h1>
        <h2>Number / nummer: </h2>
        <p>+46 40 781 85</p>
        <h2>Address / Adress:</h2>
        <a href="https://www.google.com/maps/place/Holmgatan+4,+211+45+Malm%C3%B6,+Sverige/data=!4m2!3m1!1s0x4653a15728036ccd:0xc1965a794196bf1f?sa=X&ved=2ahUKEwi_srDD3eGCAxW-m1YBHROCAAsQ8gF6BAgKEAA">
          Holmgatan 4, Malmö
        </a>
        <h2>Lunch:</h2>
        <p>mån-fre 11:30-14:30</p>
        <p>sön 11:30-17:00</p>
        <h2>Dinner / Middag:</h2>
        <p>ons-tors 14:30-20:00</p>
        <p>fre-lör 14:30-21:00</p>
      </div>
    </div>
  );
};

export default Om;
